
import { Component, Vue } from "vue-property-decorator";
import { authService } from "@/auth/AuthService";

@Component
export default class ForgetPasswordPage extends Vue {
  email = "";

  serverMessage = "";
  status = true;
  loading = false;

  timeout?: NodeJS.Timeout;

  onSubmit() {
    this.loading = true;
    this.$nextTick(async () => {
      await this.handleSubmit();
      this.loading = false;
    });
  }

  async handleSubmit() {
    try {
      const { data } = await authService.forgetPassword({ email: this.email });
      if (data.status === "okay") {
        this.status = true;
        this.serverMessage = `An email has been sent to ${this.email}. This may take a few minutes.`;
        await this.redirectSuccess();
      }
    } catch (e) {
      this.status = false;
      console.error(e);
      this.serverMessage = "Could not verify your email.";
    }
  }

  async redirectSuccess() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.$router.push({
        path: "/forget-password/verify",
        query: {
          email: this.email,
          success: "1",
        },
      });
    }, 1000);
  }
}
