import { render, staticRenderFns } from "./ForgetPasswordPage.vue?vue&type=template&id=89574da2&scoped=true"
import script from "./ForgetPasswordPage.vue?vue&type=script&lang=ts"
export * from "./ForgetPasswordPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89574da2",
  null
  
)

export default component.exports